export const RoutePaths = {
  // non auth
  SIGN_IN: '/sign-in',
  SIGN_UP: (userType = ':userType') => `/sign-up/${userType}`,
  CONFIRM_EMAIL: '/confirm-email',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_OTP: '/verify-otp',
  // auth
  HOME: '/',
  PROFILE: '/profile',
  CLIENTS: '/clients',
  CLIENT_DETAIL: (id?: string) => `/clients/clinic/${id || ':clinicId'}`,
  INDIVIDUAL_CLIENT_DETAIL: (id?: string) => `/clients/individual/${id || ':clientId'}`,

  HOMEWORK: '/homework',
  HOMEWORK_ACTIVITIES: '/homework/activities',
  HOMEWORK_QUESTIONNAIRES: '/homework/questionnaires',
  HOMEWORK_QUESTIONNAIRES_ADD_NEW: '/homework/questionnaires/add-new',
  HOMEWORK_WRITTEN_TASKS: '/homework/written-tasks',
  HOMEWORK_VIDEOS: '/homework/videos',
  HOMEWORK_ADD_NEW: '/homework/add-new',
  HOMEWORK_Edit: '/homework/edit',

  PSYCHO_EDUCATION: '/psycho-education',
  QUOTES_OF_THE_DAY: '/quotes-of-the-day',
  PRICING_PLAN: '/pricing-plan',
  TEMPLATES: '/templates',
  TRAINING_MATERIALS: '/training-materials',
};
