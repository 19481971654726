import { createAsyncThunk } from '@reduxjs/toolkit';
import { DASHBOARD_ACTIONS, EDashboardActions } from './dashboard-contants';
import { TFilter, TRevenueDetailResponse, TSaleOverviewResponse } from 'src/interfaces/dashboard-interface';
import { AxiosResponse } from 'axios';
import { ApiClient } from 'src/services/api-client';
import { getCurrentTimezone } from 'src/utils/common-utils';
import { IAnalyticsOverview } from './dashboard-types';
import { setLoading, clearLoading, setAnalyticsOverview } from './dashboard-slice';

export const getSaleOverview = createAsyncThunk(
  DASHBOARD_ACTIONS.GET_SALES_OVERVIEW,
  async (params: TFilter, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TSaleOverviewResponse> = await ApiClient.post(
        '/api/v1/admin/dashboard/sale-overview',
        params,
      );
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getRevenueReport = createAsyncThunk(
  DASHBOARD_ACTIONS.GET_REVENUE_REPORT,
  async (params: TFilter, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TRevenueDetailResponse> = await ApiClient.post(
        '/api/v1/admin/dashboard/revenue-report',
        {
          ...params,
          timezone: getCurrentTimezone(),
        },
      );
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getAnalyticsOverview = createAsyncThunk(
  DASHBOARD_ACTIONS.GET_ANALYTICS_OVERVIEW,
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(DASHBOARD_ACTIONS.GET_ANALYTICS_OVERVIEW));
      const response = await ApiClient.get<IAnalyticsOverview>('/api/v1/admin/analytics/overview');
      dispatch(setAnalyticsOverview(response.data));
      return response.data;
    } finally {
      dispatch(clearLoading(DASHBOARD_ACTIONS.GET_ANALYTICS_OVERVIEW));
    }
  },
);
