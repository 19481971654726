import Container from 'src/components/containers/Container';
import './Home.scss';
import CommonContent from 'src/components/containers/CommonContent';
import { BaseText } from 'src/components/text';

import RevenueReport from './revenue-report/RevenueReport';
import AnalyticsOverview from './analytics-overview/AnalyticsOverview';
import { TRootState } from 'src/stores';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { EUserActions } from 'src/stores/user/user-constants';

const Home = () => {
  const profile = useSelector((state: TRootState) => state.user.profile);
  const { loading } = useSelector((state: TRootState) => ({
    loading: state.loading[EUserActions.GET_ADMIN_PROFILE],
  }));

  return (
    <Container className="Home">
      <div className="Home__header">
        {loading ? (
          <div className="Home__header-loading">
            <Spin />
          </div>
        ) : (
          <div>
            <BaseText>Welcome back, {`${profile?.firstName} ${profile?.lastName}`}</BaseText>
            <BaseText>Here&apos;s what&apos;s happening with ANTSA today.</BaseText>
          </div>
        )}
      </div>
      <AnalyticsOverview />
      <RevenueReport />
    </Container>
  );
};

export default Home;
