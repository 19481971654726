import { ColumnsType } from 'antd/es/table';
import Table from 'src/components/table';
import { FilterIcon } from 'src/assets/icons';
import { BaseText } from 'src/components/text';
import { TFilterValues } from 'src/interfaces/common-interface';
import './ClientsTable.scss';
import { TClient } from 'src/interfaces/clients-interface';
import StatusBox from 'src/components/status-box';
import { EPaymentStatus, EProfileStatus } from 'src/variables/enum-variables';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'src/routes/routes-constants';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import type { TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';

type TableColumnType = {
  text: string;
  value: string;
}

const formatDate = (date: string) => {
  return dayjs(date).format('MMM D, YYYY');
};

const sortDates = (a: string, b: string) => {
  return dayjs(a).unix() - dayjs(b).unix();
};

export const ClientsTable = ({
  data,
  totalPage,
  currentPage,
  isClinicPractitionerTab,
  isClientTab,
  loading,
  onChangePage,
  onClickRow,
  onFilter,
}: {
  data: TClient[];
  totalPage: number;
  currentPage: number;
  isClinicPractitionerTab: boolean;
  isClientTab: boolean;
  loading?: boolean;
  onChangePage: (page: number) => void;
  onClickRow: (rowData: TClient) => void;
  onFilter: (values: TFilterValues) => void;
}) => {
  const navigate = useNavigate();
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});

  // Reset filters and trigger data refresh when tab changes
  useEffect(() => {
    setFilteredInfo({});
    onFilter({}); // Reset filters in parent
    onChangePage(0); // Reset to first page
  }, [isClinicPractitionerTab, isClientTab]);

  const getColumns = (): ColumnsType<TClient> => {
    if (isClientTab) {
      return [
        {
          title: 'Name',
          key: 'name',
          render: (_: unknown, record: TClient) => (
            <div>
              {record.firstName} {record.lastName}
            </div>
          ),
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Total Practitioners',
          dataIndex: 'totalPractitioners',
          key: 'totalPractitioners',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status: string) => <StatusBox status={status} />,
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date: string) => formatDate(date),
          sorter: (a, b) => sortDates(a.createdAt, b.createdAt),
          sortDirections: ['descend', 'ascend'],
          defaultSortOrder: 'descend'
        },
      ];
    }

    if (isClinicPractitionerTab) {
      return [
        {
          title: 'Clinic owner name',
          key: 'clinicOwnerName',
          render: (_: unknown, record: TClient) => (
            <div className="details_container">
              <div className="name">
                <BaseText type="body1">
                  {record.ownerFirstName} {record.ownerLastName}
                </BaseText>
                <BaseText type="body1">{record.ownerEmail}</BaseText>
              </div>
            </div>
          ),
          width: '33%',
        },
        {
          title: 'Clinic name',
          key: 'clinicName',
          dataIndex: 'clinicName',
        },
        {
          title: 'Pricing Plan',
          key: 'pricingPlan',
          render: (_: unknown, record: TClient) => 
            record.pricingPlan && <BaseText>${record.pricingPlan}</BaseText>,
        },
        {
          title: 'Active practitioners',
          key: 'activePractitioner',
          dataIndex: 'activePractitioner',
        },
        {
          title: 'Active clients',
          key: 'activeClient',
          dataIndex: 'activeClient',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          width: 160,
          filteredValue: filteredInfo.status || null,
          filters: [
            {
              text: 'Active',
              value: EPaymentStatus.ACTIVE,
            },
            {
              text: 'Cancelled',
              value: EPaymentStatus.CANCELED,
            },
            {
              text: 'Free trial',
              value: EPaymentStatus.TRIALING,
            },
            {
              text: 'Pending',
              value: EProfileStatus.PENDING,
            },
          ],
          onFilter: (value: string | number | boolean, record: TClient) => {
            const filterValue = value as string;
            if (filterValue === EProfileStatus.PENDING) {
              return record.status === null || record.status === undefined || record.status === '';
            }
            return record.status === filterValue;
          },
          render: (_: unknown, record: TClient) => (
            <StatusBox status={record.status || EProfileStatus.PENDING} />
          ),
          filterIcon: () => (
            <div className="filter_custom">
              <FilterIcon />
            </div>
          ),
        },
        {
          title: 'Created Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date: string) => formatDate(date),
          sorter: (a, b) => sortDates(a.createdAt, b.createdAt),
          sortDirections: ['descend', 'ascend'],
          defaultSortOrder: 'descend'
        },
      ];
    }

    return [
      {
        title: 'Practice name',
        key: 'practiceName',
        render: (_: unknown, record: TClient) => (
          <div className="details_container">
            <div className="name">
              <BaseText type="body1">
                {record.ownerFirstName} {record.ownerLastName}
              </BaseText>
            </div>
          </div>
        ),
      },
      {
        title: 'Practice email',
        key: 'practiceEmail',
        dataIndex: 'ownerEmail',
      },
      {
        title: 'Pricing Plan',
        key: 'pricingPlan',
        render: (_: unknown, record: TClient) => 
          record.pricingPlan && <BaseText>${record.pricingPlan}</BaseText>,
      },
      {
        title: 'Active clients',
        key: 'activeClient',
        dataIndex: 'activeClient',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: 160,
        filteredValue: filteredInfo.status || null,
        filters: [
          {
            text: 'Active',
            value: EPaymentStatus.ACTIVE,
          },
          {
            text: 'Cancelled',
            value: EPaymentStatus.CANCELED,
          },
          {
            text: 'Free trial',
            value: EPaymentStatus.TRIALING,
          },
          {
            text: 'Pending',
            value: EProfileStatus.PENDING,
          },
        ],
        onFilter: (value: string | number | boolean, record: TClient) => {
          const filterValue = value as string;
          if (filterValue === EProfileStatus.PENDING) {
            return record.status === null || record.status === undefined || record.status === '';
          }
          return record.status === filterValue;
        },
        render: (_: unknown, record: TClient) => (
          <StatusBox status={record.status || EProfileStatus.PENDING} />
        ),
        filterIcon: () => (
          <div className="filter_custom">
            <FilterIcon />
          </div>
        ),
      },
      {
        title: 'Created Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date: string) => formatDate(date),
        sorter: (a, b) => sortDates(a.createdAt, b.createdAt),
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'descend'
      },
    ];
  };

  const handleRowClick = (record: TClient) => {
    console.log('Row clicked:', { isClientTab, record });
    if (isClientTab) {
      console.log('Navigating to individual client detail');
      navigate(RoutePaths.INDIVIDUAL_CLIENT_DETAIL(record.id));
    } else {
      console.log('Navigating to clinic/practice detail');
      navigate(RoutePaths.CLIENT_DETAIL(record.id));
    }
    onClickRow(record);
  };

  const handleChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TClient> | SorterResult<TClient>[],
    extra: TableCurrentDataSource<TClient>
  ) => {
    setFilteredInfo(filters);
    
    // Convert filters to the format expected by onFilter
    const filterValues: TFilterValues = {};
    if (filters.status && filters.status.length > 0) {
      // Make sure all filter values are correctly passed to parent component
      filterValues.status = filters.status as string[];
      
      // Reset to first page when filter changes
      if (currentPage !== 0) {
        onChangePage(0);
      }
    }
    onFilter(filterValues);
  };

  return (
    <Table
      className="ClientsTable"
      dataSource={data.map(item => ({ ...item, key: item.id }))}
      columns={getColumns()}
      loading={loading}
      pagination={{
        currentPage,
        totalPage,
        pageSize: 15,
        totalRecord: totalPage * 15
      }}
      onClickRow={handleRowClick}
      onFilter={onFilter}
      onChangePage={onChangePage}
      onChange={handleChange}
      locale={{
        emptyText: 'No result found',
      }}
      scroll={{ x: 'max-content' }}
    />
  );
};

export default ClientsTable;
