import { AxiosResponse } from 'axios';
import { ApiClient } from './api-client';

// Match the enum from the backend
export enum DashboardVideoType {
  VIDEO = 'video',
  ARTICLE = 'article',
}

export interface DashboardVideo {
  id: string;
  title: string;
  description?: string;
  type: DashboardVideoType;
  duration?: string;
  thumbnail: string;
  url: string;
  order: number;
  created_at?: string;
  updated_at?: string;
}

export interface CreateDashboardVideoDto {
  title: string;
  description?: string;
  type: DashboardVideoType;
  duration?: string;
  thumbnail: string;
  url: string;
  order?: number;
}

// Using type alias instead of interface to avoid the empty interface warning
export type UpdateDashboardVideoDto = Partial<CreateDashboardVideoDto>;

export const getAdminDashboardVideos = async (): Promise<DashboardVideo[]> => {
  const response: AxiosResponse<DashboardVideo[]> = await ApiClient.get('/api/v1/dashboard-videos');
  return response.data;
};

export const getAdminDashboardVideo = async (id: string): Promise<DashboardVideo> => {
  const response: AxiosResponse<DashboardVideo> = await ApiClient.get(`/api/v1/dashboard-videos/${id}`);
  return response.data;
};

export const createAdminDashboardVideo = async (video: CreateDashboardVideoDto): Promise<DashboardVideo> => {
  const response: AxiosResponse<DashboardVideo> = await ApiClient.post('/api/v1/dashboard-videos', video);
  return response.data;
};

export const updateAdminDashboardVideo = async (id: string, video: UpdateDashboardVideoDto): Promise<DashboardVideo> => {
  const response: AxiosResponse<DashboardVideo> = await ApiClient.patch(`/api/v1/dashboard-videos/${id}`, video);
  return response.data;
};

export const deleteAdminDashboardVideo = async (id: string): Promise<void> => {
  await ApiClient.delete(`/api/v1/dashboard-videos/${id}`);
};

export const updateAdminDashboardVideoOrder = async (id: string, order: number): Promise<DashboardVideo> => {
  const response: AxiosResponse<DashboardVideo> = await ApiClient.patch(`/api/v1/dashboard-videos/${id}/order`, { order });
  return response.data;
}; 