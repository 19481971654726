import { createSlice } from '@reduxjs/toolkit';

import { getAdminProfile, login } from './user-actions';
import { TMyProfileResponse } from 'src/interfaces/profile-interface';
import { EUserActions } from './user-constants';

interface IUser {
  username: string;
  password: string;
  profile?: TMyProfileResponse;
  loading: {
    [key in EUserActions]: boolean;
  };
}

const initialState: IUser = {
  username: '',
  password: '',
  profile: undefined,
  loading: {
    [EUserActions.SET_USER]: false,
    [EUserActions.GET_ADMIN_PROFILE]: false,
    [EUserActions.UPDATE_PROFILE_ADMIN]: false,
    [EUserActions.CHANGE_PASSWORD]: false,
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, (state, action) => {
        state.username = action.payload.username;
        state.password = action.payload.password;
      })
      .addCase(getAdminProfile.pending, (state) => {
        state.loading[EUserActions.GET_ADMIN_PROFILE] = true;
      })
      .addCase(getAdminProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.loading[EUserActions.GET_ADMIN_PROFILE] = false;
      })
      .addCase(getAdminProfile.rejected, (state) => {
        state.loading[EUserActions.GET_ADMIN_PROFILE] = false;
      });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice;
