import { useEffect, useState } from 'react';
import { Empty, List, Select, Spin } from 'antd';
import Button from 'src/components/button';
import CommonContent from 'src/components/containers/CommonContent';
import Container from 'src/components/containers/Container';
import { SearchInput } from 'src/components/input';
import { BaseText } from 'src/components/text';
import { VideoCameraOutlined } from '@ant-design/icons';

import {
  DashboardVideo,
  getAdminDashboardVideos,
  createAdminDashboardVideo,
  updateAdminDashboardVideo,
  deleteAdminDashboardVideo,
  CreateDashboardVideoDto,
  UpdateDashboardVideoDto,
  DashboardVideoType
} from 'src/services/dashboard-videos-service';
import { ETrainingMaterialAction, ITrainingMaterialFormValues } from './constants';
import TrainingMaterialActionModal from './components/TrainingMaterialActionModal';
import TrainingMaterialItem from './components/TrainingMaterialItem';
import useDebounce from 'src/hooks/useDebounce';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';

import './TrainingMaterials.scss';

const { Option } = Select;

const TrainingMaterials = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [trainingMaterials, setTrainingMaterials] = useState<DashboardVideo[]>([]);
  const [filteredMaterials, setFilteredMaterials] = useState<DashboardVideo[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const searchTextDebounce = useDebounce(searchText);
  // Always filter by "video" type since we're temporarily removing articles
  const typeFilter = "video";
  
  const [actionModalInfo, setActionModalInfo] = useState<{
    isOpen: boolean;
    type: ETrainingMaterialAction;
    selectedMaterial?: DashboardVideo;
  }>({
    isOpen: false,
    type: ETrainingMaterialAction.ADD_NEW,
    selectedMaterial: undefined,
  });

  const fetchTrainingMaterials = async () => {
    try {
      setIsLoading(true);
      const data = await getAdminDashboardVideos();
      setTrainingMaterials(data);
    } catch (error) {
      showErrorToast('Failed to fetch training videos');
      console.error('Error fetching training videos:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainingMaterials();
  }, []);

  useEffect(() => {
    let result = [...trainingMaterials];
    
    // Apply search filter
    if (searchTextDebounce) {
      const lowerCaseSearch = searchTextDebounce.toLowerCase();
      result = result.filter(
        item => 
          item.title.toLowerCase().includes(lowerCaseSearch) || 
          (item.description && item.description.toLowerCase().includes(lowerCaseSearch))
      );
    }
    
    // Filter to only show videos
    result = result.filter(item => item.type === DashboardVideoType.VIDEO);
    
    // Sort by order
    result.sort((a, b) => a.order - b.order);
    
    setFilteredMaterials(result);
  }, [trainingMaterials, searchTextDebounce]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const closeActionModal = () => {
    setActionModalInfo({
      isOpen: false,
      type: ETrainingMaterialAction.ADD_NEW,
      selectedMaterial: undefined,
    });
  };

  const openAddModal = () => {
    setActionModalInfo({
      isOpen: true,
      type: ETrainingMaterialAction.ADD_NEW,
      selectedMaterial: undefined,
    });
  };

  const openEditModal = (material: DashboardVideo) => {
    setActionModalInfo({
      isOpen: true,
      type: ETrainingMaterialAction.EDIT,
      selectedMaterial: material,
    });
  };

  const openDeleteModal = (material: DashboardVideo) => {
    setActionModalInfo({
      isOpen: true,
      type: ETrainingMaterialAction.DELETE,
      selectedMaterial: material,
    });
  };

  const handleCreateMaterial = async (values: ITrainingMaterialFormValues) => {
    try {
      const newMaterial: CreateDashboardVideoDto = {
        ...values,
        // Always set type to VIDEO
        type: DashboardVideoType.VIDEO,
        order: trainingMaterials.length + 1,
      };
      
      await createAdminDashboardVideo(newMaterial);
      showSuccessToast('Training video has been created successfully');
      fetchTrainingMaterials();
      closeActionModal();
    } catch (error) {
      showErrorToast('Failed to create training video');
      console.error('Error creating training video:', error);
    }
  };

  const handleUpdateMaterial = async (values: ITrainingMaterialFormValues) => {
    try {
      if (!actionModalInfo.selectedMaterial) return;
      
      const updatedMaterial: UpdateDashboardVideoDto = {
        ...values,
        // Always set type to VIDEO
        type: DashboardVideoType.VIDEO
      };
      
      await updateAdminDashboardVideo(actionModalInfo.selectedMaterial.id, updatedMaterial);
      showSuccessToast('Training video has been updated successfully');
      fetchTrainingMaterials();
      closeActionModal();
    } catch (error) {
      showErrorToast('Failed to update training video');
      console.error('Error updating training video:', error);
    }
  };

  const handleDeleteMaterial = async () => {
    try {
      if (!actionModalInfo.selectedMaterial) return;
      
      await deleteAdminDashboardVideo(actionModalInfo.selectedMaterial.id);
      showSuccessToast('Training video has been deleted successfully');
      fetchTrainingMaterials();
      closeActionModal();
    } catch (error) {
      showErrorToast('Failed to delete training video');
      console.error('Error deleting training video:', error);
    }
  };

  return (
    <Container className="TrainingMaterials">
      <CommonContent
        title="Training Videos"
        action={
          <Button onClick={openAddModal}>
            <BaseText>Add New Video</BaseText>
          </Button>
        }
      >
        <div className="TrainingMaterials__header">
          <div className="TrainingMaterials__filters">
            <SearchInput
              placeholder="Search by title or description"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 300 }}
            />
            {/* Type filter temporarily removed - only showing videos */}
          </div>
        </div>

        {isLoading ? (
          <div style={{ textAlign: 'center', padding: '40px 0' }}>
            <Spin size="large" />
          </div>
        ) : filteredMaterials.length > 0 ? (
          <List
            className="TrainingMaterials__list"
            dataSource={filteredMaterials}
            renderItem={(item) => (
              <TrainingMaterialItem
                material={item}
                onEdit={() => openEditModal(item)}
                onDelete={() => openDeleteModal(item)}
              />
            )}
          />
        ) : (
          <div className="TrainingMaterials__empty">
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  No training videos found. {searchText ? 'Try changing your search.' : ''}
                </span>
              }
            />
          </div>
        )}
      </CommonContent>

      <TrainingMaterialActionModal
        open={actionModalInfo.isOpen}
        type={actionModalInfo.type}
        defaultMaterial={actionModalInfo.selectedMaterial}
        onClose={closeActionModal}
        onCreate={handleCreateMaterial}
        onUpdate={handleUpdateMaterial}
        onDelete={handleDeleteMaterial}
      />
    </Container>
  );
};

export default TrainingMaterials; 