import dayjs from 'dayjs';
import StatusBox from 'src/components/status-box';
import { BaseText } from 'src/components/text';
import SubscriptionChangelogList from 'src/containers/clients/SubscriptionChangelogList';
import SubscriptionPaymentLogList from 'src/containers/clients/SubscriptionPaymentLogList';
import {
  EGetListClientsType,
  TClinicAndSoloGeneralInformation,
  TClinicAndSoloSubscriptionInfo,
  TGetClinicAndSoloSubscriptionChangeLogResponse,
  TGetClinicAndSoloSubscriptionPaymentLogResponse,
} from 'src/interfaces/clients-interface';
import SubscriptionCard from './SubscriptionCard';
import './SubscriptionInfo.scss';
import { Space, Button, Modal, InputNumber, message, Card, Typography, Row, Col } from 'antd';
import { useState } from 'react';
import { useAppDispatch } from 'src/stores';
import { extendSubscription, extendTrial, cancelSubscription, reactivateAsTrial } from 'src/stores/clients';
import { EPaymentStatus } from 'src/variables/enum-variables';

const { Text, Title } = Typography;

interface ISubscriptionInfoProps {
  generalInformation: TClinicAndSoloGeneralInformation;
  subscriptionInfo: TClinicAndSoloSubscriptionInfo;
  subscriptionChangeLog?: TGetClinicAndSoloSubscriptionChangeLogResponse;
  subscriptionPaymentLog?: TGetClinicAndSoloSubscriptionPaymentLogResponse;
  onLoadMoreSubscriptionChangeLog: () => void;
  onLoadMoreSubscriptionPaymentLog: () => void;
  onDownloadInvoice: (invoiceId: string) => void;
}

const SubscriptionInfo = ({
  generalInformation,
  subscriptionInfo,
  subscriptionChangeLog,
  subscriptionPaymentLog,
  onLoadMoreSubscriptionChangeLog,
  onLoadMoreSubscriptionPaymentLog,
  onDownloadInvoice,
}: ISubscriptionInfoProps) => {
  console.log('Subscription Status:', {
    status: subscriptionInfo?.status,
    expectedStatus: EPaymentStatus.CANCELED,
    isMatch: subscriptionInfo?.status === EPaymentStatus.CANCELED,
    fullSubscriptionInfo: subscriptionInfo
  });
  
  const dispatch = useAppDispatch();
  const [isExtendModalVisible, setIsExtendModalVisible] = useState(false);
  const [isTrialModalVisible, setIsTrialModalVisible] = useState(false);
  const [isReactivateModalVisible, setIsReactivateModalVisible] = useState(false);
  const [durationInDays, setDurationInDays] = useState(30);
  const [trialDays, setTrialDays] = useState(7);
  const [reactivationDays, setReactivationDays] = useState(30);

  const handleExtendSubscription = async () => {
    try {
      await dispatch(extendSubscription({
        clinicId: generalInformation.id,
        durationInDays
      }));
      message.success('Subscription extended successfully');
      setIsExtendModalVisible(false);
    } catch (error) {
      message.error('Failed to extend subscription');
    }
  };

  const handleExtendTrial = async () => {
    try {
      console.log('Extending trial for clinic:', generalInformation.id, 'by', trialDays, 'days');
      const result = await dispatch(extendTrial({
        clinicId: generalInformation.id,
        trialDays
      })).unwrap(); // Use unwrap to get the actual response

      console.log('Trial extension response:', result);
      
      if (!result || !result.trialEnd) {
        throw new Error('Invalid response from trial extension');
      }

      const newTrialEnd = dayjs(result.trialEnd);
      const currentTrialEnd = dayjs(subscriptionInfo.trialEnd);
      
      if (!newTrialEnd.isAfter(currentTrialEnd)) {
        throw new Error('Trial end date was not extended');
      }

      message.success(`Trial period extended successfully until ${newTrialEnd.format('MMMM D, YYYY')}`);
      setIsTrialModalVisible(false);
    } catch (error) {
      console.error('Trial extension error:', error);
      message.error(error instanceof Error ? error.message : 'Failed to extend trial period');
    }
  };

  const handleCancelSubscription = async () => {
    Modal.confirm({
      title: 'Cancel Subscription',
      content: 'Are you sure you want to cancel this subscription? This action cannot be undone.',
      okText: 'Yes, Cancel',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          await dispatch(cancelSubscription({
            clinicId: generalInformation.id,
            cancelImmediately: true
          }));
          message.success('Subscription cancelled successfully');
        } catch (error) {
          message.error('Failed to cancel subscription');
        }
      }
    });
  };

  const handleReactivateAsTrial = async () => {
    try {
      console.log('Reactivating subscription for clinic:', generalInformation.id, 'as a', reactivationDays, 'day trial');
      const result = await dispatch(reactivateAsTrial({
        clinicId: generalInformation.id,
        trialDays: reactivationDays
      })).unwrap();

      console.log('Reactivation response:', result);
      
      if (!result) {
        throw new Error('Empty response from reactivation');
      }
      
      const endDateMsg = result.trialEnd 
        ? ` until ${dayjs(result.trialEnd).format('MMMM D, YYYY')}` 
        : '';

      message.success(`Subscription reactivated as a ${reactivationDays}-day trial${endDateMsg}`);
      setIsReactivateModalVisible(false);
      
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error('Reactivation error:', error);
      message.error('Failed to reactivate subscription. The operation may have succeeded but returned incomplete data.');
    }
  };

  const getRemainingDays = (endDate: string | null | undefined) => {
    if (!endDate) return 0;
    const today = dayjs().startOf('day');
    const end = dayjs(endDate).startOf('day');
    return end.diff(today, 'day');
  };

  const getEffectiveStatus = () => {
    // If subscription status is available, use it
    if (subscriptionInfo?.status) {
      return subscriptionInfo.status.toString().toUpperCase();
    }
    
    // Otherwise fall back to general information status
    if (generalInformation?.status) {
      return generalInformation.status.toString().toUpperCase();
    }
    
    // If neither is available, return unknown
    return 'UNKNOWN';
  };

  const isSubscriptionCancelled = () => {
    const status = getEffectiveStatus();
    return status === 'CANCELED' || status === 'CANCELLED';
  };

  const isSubscriptionActive = () => {
    const status = getEffectiveStatus();
    return status === 'ACTIVE' || status === 'TRIALING';
  };

  const isTrialActive = () => {
    const status = getEffectiveStatus();
    return status === 'TRIALING' && 
           subscriptionInfo?.trialEnd && 
           getRemainingDays(subscriptionInfo.trialEnd) > 0;
  };

  const getSubscriptionStatus = () => {
    if (!subscriptionInfo) {
      return <Text type="secondary">No Subscription</Text>;
    }
    
    const status = getEffectiveStatus();

    if (status === 'ACTIVE') {
      return <Text type="success">Active</Text>;
    } else if (status === 'TRIALING') {
      return <Text type="warning">Trial</Text>;
    } else if (status === 'CANCELED' || status === 'CANCELLED') {
      return <Text type="danger">Cancelled</Text>;
    } else {
      return <Text type="secondary">{status}</Text>;
    }
  };

  if (!subscriptionInfo) {
    return (
      <div className="SubscriptionInfo">
        <Card>
          <Space direction="vertical" size={16}>
            <div>
              <Title level={5}>Subscription Status</Title>
              <Text type="secondary">No Active Subscription</Text>
            </div>
          </Space>
        </Card>
      </div>
    );
  }

  return (
    <div className="SubscriptionInfo">
      <Row gutter={[24, 24]}>
        {/* Main Status Card */}
        <Col xs={24}>
          <Card>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Space direction="vertical" size={16}>
                  <div>
                    <Title level={5}>Subscription Status</Title>
                    {getSubscriptionStatus()}
                  </div>
                  
                  {isTrialActive() && (
                    <div>
                      <Title level={5}>Trial Period</Title>
                      <Text>
                        {getRemainingDays(subscriptionInfo.trialEnd)} days remaining
                        (Ends: {dayjs(subscriptionInfo.trialEnd).format('MMMM D, YYYY')})
                      </Text>
                    </div>
                  )}
                </Space>
              </Col>

              <Col xs={24} md={8}>
                <Space direction="vertical" size={16}>
                  {subscriptionInfo.nextPaymentAt && (
                    <div>
                      <Title level={5}>Current Period</Title>
                      <Text>
                        Next payment: {dayjs(subscriptionInfo.nextPaymentAt).format('MMMM D, YYYY')}
                        ({getRemainingDays(subscriptionInfo.nextPaymentAt)} days remaining)
                      </Text>
                    </div>
                  )}
                </Space>
              </Col>

              <Col xs={24} md={8}>
                <Space direction="vertical" size={16}>
                  <Title level={5}>Actions</Title>
                  <Space wrap>
                    {isSubscriptionActive() && (
                      <>
                        <Button type="primary" onClick={() => setIsExtendModalVisible(true)}>
                          Extend Subscription
                        </Button>
                        <Button danger onClick={handleCancelSubscription}>
                          Cancel Subscription
                        </Button>
                      </>
                    )}
                    {isTrialActive() && (
                      <Button type="primary" onClick={() => setIsTrialModalVisible(true)}>
                        Extend Trial
                      </Button>
                    )}
                    {isSubscriptionCancelled() && (
                      <Button type="primary" onClick={() => setIsReactivateModalVisible(true)}>
                        Reactivate as Trial
                      </Button>
                    )}
                  </Space>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>

        {/* Subscription Details */}
        <Col xs={24} lg={12}>
          <Card>
            <div className="SubscriptionInfo__current-subscription">
              <div className="SubscriptionInfo__current-subscription-heading">
                <BaseText type="title">Current subscription</BaseText>
                <StatusBox status={getEffectiveStatus()} />
              </div>
              {subscriptionInfo.plan && (
                <SubscriptionCard
                  packageName={subscriptionInfo.plan.package.name}
                  packageAmount={subscriptionInfo.plan.amount || 0}
                  packagePrice={subscriptionInfo.plan.price || 0}
                />
              )}
              {subscriptionInfo.registeredAt && (
                <BaseText className="SubscriptionInfo__current-subscription-registered">
                  Registered ANTSA from {dayjs(subscriptionInfo.registeredAt).format('DD/MM/YYYY')}
                </BaseText>
              )}
            </div>
          </Card>
        </Col>

        {/* Activities Card */}
        <Col xs={24} lg={12}>
          <Card>
            <div className="SubscriptionInfo__activities">
              <BaseText type="title" className="SubscriptionInfo__activities-heading">
                Activities this month
              </BaseText>
              {subscriptionInfo.nextPaymentAt && (
                <BaseText>
                  Next payment due: {dayjs(subscriptionInfo.nextPaymentAt).format('DD/MM/YYYY')}
                </BaseText>
              )}
              {generalInformation.type === EGetListClientsType.CLINIC && 
               subscriptionInfo.totalPractitionerActive !== undefined && (
                <BaseText>Active users: {subscriptionInfo.totalPractitionerActive}</BaseText>
              )}
            </div>
          </Card>
        </Col>

        {/* Logs Section */}
        <Col xs={24} lg={12}>
          <Card>
            <BaseText type="title" className="SubscriptionInfo__logs-title">
              Subscription changelog
            </BaseText>
            <SubscriptionChangelogList
              data={subscriptionChangeLog?.data || []}
              hasMore={subscriptionChangeLog ? 
                subscriptionChangeLog.currentPage < subscriptionChangeLog.totalPage : false}
              onLoadMore={onLoadMoreSubscriptionChangeLog}
            />
          </Card>
        </Col>

        <Col xs={24} lg={12}>
          <Card>
            <BaseText type="title" className="SubscriptionInfo__logs-title">
              Payment log
            </BaseText>
            <SubscriptionPaymentLogList
              data={subscriptionPaymentLog?.data || []}
              hasMore={subscriptionPaymentLog ? subscriptionPaymentLog.hasMore : false}
              onLoadMore={onLoadMoreSubscriptionPaymentLog}
              onDownloadInvoice={onDownloadInvoice}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        title="Extend Subscription"
        visible={isExtendModalVisible}
        onOk={handleExtendSubscription}
        onCancel={() => setIsExtendModalVisible(false)}
      >
        <Space direction="vertical">
          <div>Enter number of days to extend:</div>
          <InputNumber
            min={1}
            value={durationInDays}
            onChange={(value) => setDurationInDays(value || 30)}
          />
        </Space>
      </Modal>

      <Modal
        title="Extend Trial"
        visible={isTrialModalVisible}
        onOk={handleExtendTrial}
        onCancel={() => setIsTrialModalVisible(false)}
      >
        <Space direction="vertical">
          <div>Enter number of trial days to add:</div>
          <InputNumber
            min={1}
            value={trialDays}
            onChange={(value) => setTrialDays(value || 7)}
          />
        </Space>
      </Modal>

      <Modal
        title="Reactivate Subscription as Trial"
        visible={isReactivateModalVisible}
        onOk={handleReactivateAsTrial}
        onCancel={() => setIsReactivateModalVisible(false)}
      >
        <Space direction="vertical">
          <div>This will reactivate the subscription as a new trial period.</div>
          <div>Enter number of trial days:</div>
          <InputNumber
            min={1}
            value={reactivationDays}
            onChange={(value) => setReactivationDays(value || 30)}
          />
        </Space>
      </Modal>
    </div>
  );
};

export default SubscriptionInfo;
