import { EClientsAction } from './clients-constants';
import slice from './clients-slice';
import {
  getClients,
  getClinicAndSoloGeneralInformation,
  getClinicAndSoloDetailStatistic,
  getClinicAndSoloSubscriptionInfo,
  getClinicAndSoloSubscriptionChangeLog,
  getClinicAndSoloSubscriptionPaymentLog,
  getInvoiceDetail,
  getIndividualClientInfo,
  extendSubscription,
  extendTrial,
  cancelSubscription,
  resumeSubscription,
  reactivateAsTrial,
} from './clients-actions';

export { EClientsAction };

export const { reducer: clientsReducer } = slice;

export {
  getClients,
  getClinicAndSoloGeneralInformation,
  getClinicAndSoloDetailStatistic,
  getClinicAndSoloSubscriptionInfo,
  getClinicAndSoloSubscriptionChangeLog,
  getClinicAndSoloSubscriptionPaymentLog,
  getInvoiceDetail,
  getIndividualClientInfo,
  extendSubscription,
  extendTrial,
  cancelSubscription,
  resumeSubscription,
  reactivateAsTrial,
};

export default slice;
