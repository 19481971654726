/* eslint-disable react/prop-types */
import { Button, Card, Col, Modal, Row, Space, Tag, Typography } from 'antd';
import { DeleteOutlined, EditOutlined, EyeOutlined, ProfileOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { DashboardVideo, DashboardVideoType } from 'src/services/dashboard-videos-service';
import { BaseText } from 'src/components/text';
import { useState } from 'react';
import './TrainingMaterialItem.scss';

const { Text } = Typography;

interface ITrainingMaterialItemProps {
  material: DashboardVideo;
  onEdit: () => void;
  onDelete: () => void;
}

const TrainingMaterialItem: React.FC<ITrainingMaterialItemProps> = ({ material, onEdit, onDelete }) => {
  const [previewVisible, setPreviewVisible] = useState(false);

  const showPreview = () => {
    setPreviewVisible(true);
  };

  const hidePreview = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Card className="TrainingMaterialItem" hoverable>
        <Row gutter={16} align="middle">
          <Col span={4}>
            <div className="thumbnail-container">
              <img src={material.thumbnail} alt={material.title} className="thumbnail" />
              {material.type === DashboardVideoType.VIDEO && (
                <div className="play-button-overlay" onClick={showPreview}>
                  <VideoCameraOutlined />
                </div>
              )}
            </div>
          </Col>
          <Col span={16}>
            <div className="material-content">
              <div className="material-title">
                {material.type === DashboardVideoType.VIDEO ? (
                  <VideoCameraOutlined className="material-icon video" />
                ) : (
                  <ProfileOutlined className="material-icon article" />
                )}
                <Text strong>{material.title}</Text>
              </div>
              <div className="material-info">
                <Tag color={material.type === DashboardVideoType.VIDEO ? 'blue' : 'green'}>
                  {material.type === DashboardVideoType.VIDEO ? 'Video' : 'Article'}
                </Tag>
                {material.duration && <Text type="secondary">{material.duration}</Text>}
              </div>
              {material.description && (
                <Text type="secondary" className="material-description" ellipsis={{ tooltip: material.description }}>
                  {material.description}
                </Text>
              )}
            </div>
          </Col>
          <Col span={4}>
            <Space className="material-actions">
              {material.type === DashboardVideoType.VIDEO && (
                <EyeOutlined className="preview-icon" onClick={showPreview} />
              )}
              <EditOutlined className="edit-icon" onClick={onEdit} />
              <DeleteOutlined className="delete-icon" onClick={onDelete} />
            </Space>
          </Col>
        </Row>
      </Card>

      {/* Video Preview Modal */}
      <Modal
        title={material.title}
        open={previewVisible}
        onCancel={hidePreview}
        footer={[
          <Button key="close" onClick={hidePreview}>
            Close
          </Button>
        ]}
        width={800}
        centered
      >
        {material.type === DashboardVideoType.VIDEO && (
          <div className="video-wrapper">
            <iframe
              src={material.url}
              title={material.title}
              width="100%"
              height="450"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}
      </Modal>
    </>
  );
};

export default TrainingMaterialItem; 