import { createSlice } from '@reduxjs/toolkit';
import { DASHBOARD_ACTIONS } from './dashboard-contants';
import { IAnalyticsOverview } from './dashboard-types';

import * as asyncActions from './dashboard-action';
import { TSaleOverviewResponse } from 'src/interfaces/dashboard-interface';

interface IDashboardState {
  loading: Record<string, boolean>;
  analyticsOverview: IAnalyticsOverview | null;
  saleOverview: TSaleOverviewResponse | undefined;
}

const initialState: IDashboardState = {
  loading: {},
  analyticsOverview: null,
  saleOverview: undefined,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading[action.payload] = true;
    },
    clearLoading: (state, action) => {
      state.loading[action.payload] = false;
    },
    setAnalyticsOverview: (state, action) => {
      state.analyticsOverview = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(asyncActions.getSaleOverview.fulfilled, (state, action) => {
      state.saleOverview = action.payload as TSaleOverviewResponse;
    });
  },
});

export const { setLoading, clearLoading, setAnalyticsOverview } = dashboardSlice.actions;
export default dashboardSlice.reducer;
