import { createSlice } from '@reduxjs/toolkit';
import {
  getClients,
  getClinicAndSoloGeneralInformation,
  getClinicAndSoloDetailStatistic,
  getClinicAndSoloSubscriptionInfo,
  getClinicAndSoloSubscriptionChangeLog,
  getClinicAndSoloSubscriptionPaymentLog,
  getInvoiceDetail,
  getIndividualClientInfo,
} from './clients-actions';
import { TGetListClientsResponse } from 'src/interfaces/clients-interface';

interface IClientsState {
  clientList: TGetListClientsResponse | null;
  loading: boolean;
  error: string | null;
}

const initialState: IClientsState = {
  clientList: null,
  loading: false,
  error: null,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get clients
    builder.addCase(getClients.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.loading = false;
      state.clientList = action.payload;
    });
    builder.addCase(getClients.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || 'Failed to fetch clients';
    });
  },
});

export default clientsSlice;
