/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import ResponseError from 'src/interfaces/error-response-interface';

import { RoutePaths } from 'src/routes/routes-constants';
import { EAuthToken } from 'src/variables/storage';

// const DEFAULT_MAX_IDLE_TIME = 1800000;

interface CustomAxiosInstance extends AxiosInstance {
  extendSubscription(clinicId: string, durationInDays: number): Promise<any>;
  extendTrial(clinicId: string, trialDays: number): Promise<any>;
  cancelSubscription(clinicId: string, cancelImmediately: boolean): Promise<any>;
  resumeSubscription(clinicId: string): Promise<any>;
  reactivateAsTrial(clinicId: string, trialDays?: number): Promise<any>;
}

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL }) as CustomAxiosInstance;

const requestHandler = (config: AxiosRequestConfig) => {
  const atk = localStorage.getItem(EAuthToken.ACCESS_TOKEN);

  const configHeaders = {
    Authorization: atk ? `Bearer ${atk}` : '',
    ...config.headers,
  };
  config.headers = configHeaders;
  config.params = {
    ...config.params,
    version: Date.now(),
  };

  return config;
};

const responseErrorHandler = async (err: AxiosError) => {
  if (err?.response?.status === 401) {
    localStorage.clear();
    window.location.pathname = RoutePaths.SIGN_IN;
  }

  const data: any = err?.response?.data;
  const message = data?.message;

  if (message && typeof message === 'object' && message.length) {
    throw new ResponseError(message[0], data);
  }
  if (message) throw new ResponseError(message, data);
  return Promise.reject(err);
};

// let timer: NodeJS.Timeout;

// const logout = () => {
//   localStorage.clear();
//   window.location.pathname = RoutePaths.SIGN_IN;
// };

const responseSuccessHandler = async (response: AxiosResponse) => {
  // const isAuthenticated = response.config.headers?.['Authorization'];

  // timer && clearTimeout(timer);
  // if (isAuthenticated) {
  //   timer = setTimeout(logout, DEFAULT_MAX_IDLE_TIME);
  // }
  return response;
};

axiosInstance.interceptors.request.use(requestHandler, (err) => Promise.reject(err));
axiosInstance.interceptors.response.use(responseSuccessHandler, responseErrorHandler);

// Subscription Management
axiosInstance.extendSubscription = function (clinicId: string, durationInDays: number) {
  return this.patch(`/api/v1/admin/client-management/${clinicId}/extend-subscription`, { durationInDays });
};

axiosInstance.extendTrial = function (clinicId: string, trialDays: number) {
  return this.patch(`/api/v1/admin/client-management/${clinicId}/extend-trial`, { trialDays });
};

axiosInstance.reactivateAsTrial = function (clinicId: string, trialDays = 30) {
  return this.patch(`/api/v1/admin/client-management/${clinicId}/reactivate-as-trial`, { trialDays });
};

axiosInstance.cancelSubscription = function (clinicId: string, cancelImmediately: boolean) {
  return this.post('/api/v1/payments/cancel-subscription', { clinicId, cancelImmediately });
};

axiosInstance.resumeSubscription = function (clinicId: string) {
  return this.put('/api/v1/payments/resume-subscription', { clinicId });
};

export { axiosInstance as ApiClient };
