import { EUserType } from 'src/variables/enum-variables';
import { ESidebarItemLabel } from './sidebar-types';

export const ADMIN_SIDEBAR_ITEMS = [
  ESidebarItemLabel.CLIENTS,
  ESidebarItemLabel.HOMEWORK,
  ESidebarItemLabel.PSYCHOEDUCATION,
  ESidebarItemLabel.QUOTES,
  ESidebarItemLabel.PRICING_PLAN,
  ESidebarItemLabel.TEMPLATES,
  ESidebarItemLabel.TRAINING_MATERIALS,
];

export const SIDEBAR_ITEMS_BY_TYPE: { [key: string]: string[] } = {
  [EUserType.ADMIN]: ADMIN_SIDEBAR_ITEMS,
};
