import CommonContent from 'src/components/containers/CommonContent';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement, Scale, Tick, CoreScaleOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { BaseText } from 'src/components/text';
import { ChevronDownIcon } from 'src/assets/icons';
import { useEffect, useState, useMemo } from 'react';
import { EComponentName, ETimePeriodTracker } from 'src/variables/enum-variables';
import { getFilterByTitle, getFilterTitle, mapRevenue, sortValue } from 'src/utils/common-utils';
import TimePeriod from 'src/components/TimePeriod/TimePeriod';
import './RevenueReport.scss';
import RevenueDetailList from './RevenueDetailList';
import { TFilter, TRevenueDetailResponse } from 'src/interfaces/dashboard-interface';
import { useAppDispatch } from 'src/stores';
import { getRevenueReport } from 'src/stores/dashboard';
import { showErrorToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';
import dayjs from 'dayjs';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, LineElement, PointElement);

const options = {
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  plugins: {
    legend: {
      position: 'top' as const,
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function(context: any) {
          return `Revenue: $${context.parsed.y.toLocaleString()}`;
        }
      }
    }
  },
  scales: {
    x: {
      type: 'category' as const,
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 45,
        minRotation: 45
      }
    },
    y: {
      type: 'linear' as const,
      display: true,
      position: 'left' as const,
      grid: {
        color: 'rgba(0, 0, 0, 0.1)',
      },
      ticks: {
        callback: function(this: Scale<CoreScaleOptions>, tickValue: number | string, index: number, ticks: Tick[]) {
          const value = Number(tickValue);
          if (isNaN(value)) return tickValue;
          return `$${value.toLocaleString()}`;
        }
      }
    },
  },
} as const;

const RevenueReport = () => {
  const dispatch = useAppDispatch();
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<TFilter>(getFilterByTitle(ETimePeriodTracker.LAST_12_MONTHS));
  const [revenueReport, setRevenueReport] = useState<TRevenueDetailResponse[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [revenue, setRevenue] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleShowFilter = () => {
    setIsShowFilter(!isShowFilter);
  };

  const onFilter = (value: TFilter) => {
    setFilter(value);
    setIsShowFilter(false);
  };

  const getRevenue = async () => {
    setIsLoading(true);
    try {
      const result = await dispatch(getRevenueReport(filter));
      const data = result.payload as TRevenueDetailResponse;

      if (data) {
        const newData = sortValue(data);
        const withNestedKeys = Object.entries(newData).map((entry) => {
          return { [entry[0]]: entry[1] };
        });
        setRevenueReport([...withNestedKeys]);
        setLabels(Object.keys(newData));
        setRevenue(mapRevenue([...withNestedKeys]));
      }
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRevenue();
  }, [filter]);

  const totalRevenue = useMemo(() => revenue.reduce((acc, curr) => acc + curr, 0), [revenue]);
  const averageRevenue = useMemo(() => revenue.length ? totalRevenue / revenue.length : 0, [revenue, totalRevenue]);
  const revenueChange = useMemo(() => {
    if (revenue.length < 2) return 0;
    const lastMonth = revenue[revenue.length - 1];
    const previousMonth = revenue[revenue.length - 2];
    return previousMonth ? ((lastMonth - previousMonth) / previousMonth) * 100 : 0;
  }, [revenue]);

  const data = {
    labels,
    datasets: [
      {
        label: 'Revenue',
        data: revenue,
        borderColor: '#2E5BFF',
        backgroundColor: 'rgba(46, 91, 255, 0.2)',
        fill: true,
        tension: 0.4,
      },
    ],
  };

  return (
    <div className="RevenueReport">
      <div className="RevenueReport__title">
        <BaseText className="RevenueReport__title-headline">
          Revenue Report
        </BaseText>
        <BaseText className="RevenueReport__title-time">
          {`${dayjs(filter.startDate).format('MMMM D, YYYY')} - ${dayjs(filter.endDate).format('MMMM D, YYYY')}`}
        </BaseText>
      </div>
      <div className="RevenueReport__summary">
        <div className="RevenueReport__summary-item">
          <BaseText type="caption">Total Revenue</BaseText>
          <BaseText type="headline">${totalRevenue.toLocaleString()}</BaseText>
        </div>
        <div className="RevenueReport__summary-item">
          <BaseText type="caption">Average Monthly Revenue</BaseText>
          <BaseText type="headline">${averageRevenue.toLocaleString()}</BaseText>
        </div>
        <div className="RevenueReport__summary-item">
          <BaseText type="caption">Month-over-Month Change</BaseText>
          <BaseText type="headline" className={revenueChange >= 0 ? 'positive' : 'negative'}>
            {revenueChange.toFixed(1)}%
          </BaseText>
        </div>
      </div>
      <div className="RevenueReport__container">
        <div className="RevenueReport__chart">
          <Line options={options} data={data} />
        </div>
        <div className="RevenueReport__right">
          <div className="RevenueReport__right-filter">
            <div className="RevenueReport__filter" onClick={toggleShowFilter}>
              <BaseText type="caption">{getFilterTitle(filter.type)}</BaseText>
              <ChevronDownIcon />
            </div>
          </div>
          <div>
            <RevenueDetailList
              data={revenueReport}
              hasMore={true}
              onLoadMore={() => console.log('')}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {isShowFilter ? (
        <TimePeriod
          isOpen={true}
          onClose={toggleShowFilter}
          value={filter}
          onSubmit={onFilter}
          componentName={EComponentName.REVENUE_REPORT}
        />
      ) : null}
    </div>
  );
};

export default RevenueReport;
