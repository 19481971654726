import { DashboardVideoType } from 'src/services/dashboard-videos-service';

export const DEFAULT_TRAINING_MATERIALS_PAGE_SIZE = 10;

export enum ETrainingMaterialAction {
  ADD_NEW = 'ADD_NEW',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export interface ITrainingMaterialFormValues {
  title: string;
  description?: string;
  type: DashboardVideoType;
  duration?: string;
  thumbnail: string;
  url: string;
} 