import React, { useEffect } from 'react';
import { Card, Row, Col, Statistic, Progress, Spin, Table } from 'antd';
import { UserOutlined, ClockCircleOutlined, FileTextOutlined, BarChartOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from 'src/stores';
import { getAnalyticsOverview } from 'src/stores/dashboard';
import { AppThunkDispatch } from 'src/stores';
import './AnalyticsOverview.scss';

const AnalyticsOverview = () => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { analyticsOverview, loading } = useSelector((state: TRootState) => ({
    analyticsOverview: state.dashboard.analyticsOverview,
    loading: state.loading.GET_ANALYTICS_OVERVIEW,
  }));

  useEffect(() => {
    dispatch(getAnalyticsOverview());
  }, [dispatch]);

  if (loading || !analyticsOverview) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <Spin />
      </div>
    );
  }

  const { userStats, homeworkStats, templateStats } = analyticsOverview;

  const popularTemplatesColumns = [
    {
      title: 'Template Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Usage Count',
      dataIndex: 'usageCount',
      key: 'usageCount',
      render: (count: number) => <span>{count} times</span>,
    },
  ];

  return (
    <div className="analytics-overview">
      <Card title="User Statistics">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic
                title="Total Clinics"
                value={userStats.totalClinics}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic
                title="Solo Practitioners"
                value={userStats.totalSoloPractitioners}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic
                title="Total Clients"
                value={userStats.totalClients}
                prefix={<UserOutlined />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic
                title="Average Session Time"
                value={userStats.averageSessionTime}
                prefix={<ClockCircleOutlined />}
              />
            </Card>
          </Col>
        </Row>
      </Card>

      <Card title="Template Statistics" className="template-stats-card">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic
                title="Total Sessions"
                value={templateStats.totalSessions}
                prefix={<BarChartOutlined />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic
                title="Average Session Duration"
                value={`${(templateStats.averageSessionDuration * 60).toFixed(1)} seconds`}
                prefix={<ClockCircleOutlined />}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Card>
              <Statistic
                title="Documents Generated"
                value={templateStats.totalDocumentsGenerated}
                prefix={<FileTextOutlined />}
              />
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={24}>
            <Card title="Most Popular Templates">
              <Table
                dataSource={templateStats.popularTemplates}
                columns={popularTemplatesColumns}
                pagination={false}
                size="small"
              />
            </Card>
          </Col>
        </Row>
      </Card>

      <Card title="Homework Assignment Statistics" className="homework-stats-card">
        <Row gutter={[16, 16]}>
          {homeworkStats.map((stat) => (
            <Col xs={24} sm={12} md={8} key={stat.type}>
              <Card className="stat-card">
                <Statistic
                  title={stat.type}
                  value={stat.totalAssigned}
                  suffix={`/ ${stat.totalCompleted} completed`}
                />
                <div className="progress-wrapper">
                  <Progress
                    percent={stat.completionRate}
                    status={stat.completionRate >= 80 ? 'success' : 'normal'}
                    format={(percent) => `${percent}%`}
                  />
                  <div className="completion-text">completion rate</div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};

export default AnalyticsOverview; 