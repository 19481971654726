import { createAsyncThunk } from '@reduxjs/toolkit';
import { EClientsAction } from './clients-constants';
import { AxiosResponse } from 'axios';
import {
  TClinicAndSoloGeneralInformation,
  TGetClinicAndSoloDetailStatisticParams,
  TGetClinicAndSoloGeneralInformationParams,
  TGetListClientsParams,
  TGetListClientsResponse,
  TClinicAndSoloSubscriptionInfo,
  TGetClinicAndSoloSubscriptionChangeLogParams,
  TGetClinicAndSoloSubscriptionChangeLogResponse,
  TGetClinicAndSoloSubscriptionPaymentLogParams,
  TGetClinicAndSoloSubscriptionPaymentLogResponse,
  TGetInvoiceDetailParams,
  TGetInvoiceDetailResponseData,
  IClientGeneralInfo,
} from 'src/interfaces/clients-interface';
import { ApiClient } from 'src/services/api-client';

export const getClients = createAsyncThunk(
  EClientsAction.GET_CLIENTS,
  async (params: TGetListClientsParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetListClientsResponse> = await ApiClient.get('/api/v1/admin/client-management', {
        params,
      });

      // Transform clinic/solo response to match pagination format
      if (params.type === 'CLINIC' || params.type === 'SOLO') {
        const { data, total } = response.data as any;
        return {
          currentPage: params.page,
          totalPage: Math.ceil(total / params.size),
          totalRecord: total,
          data: data
        };
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getClinicAndSoloGeneralInformation = createAsyncThunk(
  EClientsAction.GET_CLINIC_GENERAL_INFORMATION,
  async (params: TGetClinicAndSoloGeneralInformationParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TClinicAndSoloGeneralInformation> = await ApiClient.get(
        `/api/v1/admin/client-management/${params.clinicId}/general-information`,
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getClinicAndSoloDetailStatistic = createAsyncThunk(
  EClientsAction.GET_CLINIC_DETAIL_STATISTIC,
  async (params: TGetClinicAndSoloDetailStatisticParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TClinicAndSoloGeneralInformation> = await ApiClient.get(
        `/api/v1/admin/client-management/${params.clinicId}/detail-statistic`,
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getClinicAndSoloSubscriptionInfo = createAsyncThunk(
  EClientsAction.GET_SUBSCRIPTION_INFO,
  async (params: TGetClinicAndSoloDetailStatisticParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TClinicAndSoloSubscriptionInfo> = await ApiClient.get(
        `/api/v1/admin/client-management/${params.clinicId}/subscription-info`,
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getClinicAndSoloSubscriptionChangeLog = createAsyncThunk(
  EClientsAction.GET_SUBSCRIPTION_CHANGE_LOG,
  async (params: TGetClinicAndSoloSubscriptionChangeLogParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetClinicAndSoloSubscriptionChangeLogResponse> = await ApiClient.get(
        `/api/v1/admin/client-management/${params.clinicId}/subscription-change-log`,
        { params },
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getClinicAndSoloSubscriptionPaymentLog = createAsyncThunk(
  EClientsAction.GET_SUBSCRIPTION_PAYMENT_LOG,
  async (params: TGetClinicAndSoloSubscriptionPaymentLogParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetClinicAndSoloSubscriptionPaymentLogResponse> = await ApiClient.get(
        `/api/v1/admin/client-management/${params.clinicId}/payment-log`,
        { params },
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getInvoiceDetail = createAsyncThunk(
  EClientsAction.GET_INVOICE_DETAIL,
  async (params: TGetInvoiceDetailParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetInvoiceDetailResponseData> = await ApiClient.get(
        `/api/v1/admin/client-management/${params.clinicId}/view-invoice/${params.invoiceId}`,
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getIndividualClientInfo = createAsyncThunk(
  EClientsAction.GET_INDIVIDUAL_CLIENT_INFO,
  async (params: { clientId: string }, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IClientGeneralInfo> = await ApiClient.get(
        `/api/v1/admin/client-management/${params.clientId}/individual-info`
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const updateClientInfo = createAsyncThunk(
  EClientsAction.UPDATE_CLIENT_INFO,
  async (params: { clientId: string; email: string; phone: string }, { rejectWithValue }) => {
    try {
      const response = await ApiClient.patch(
        `/api/v1/admin/client-management/${params.clientId}`,
        { email: params.email, phone: params.phone }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  }
);

export const extendSubscription = createAsyncThunk(
  'clients/extendSubscription',
  async (params: { clinicId: string; durationInDays: number }) => {
    const response = await ApiClient.extendSubscription(params.clinicId, params.durationInDays);
    return response.data;
  },
);

export const extendTrial = createAsyncThunk(
  'clients/extendTrial',
  async (params: { clinicId: string; trialDays: number }) => {
    const response = await ApiClient.extendTrial(params.clinicId, params.trialDays);
    return response.data;
  },
);

export const cancelSubscription = createAsyncThunk(
  'clients/cancelSubscription',
  async (params: { clinicId: string; cancelImmediately: boolean }) => {
    const response = await ApiClient.cancelSubscription(params.clinicId, params.cancelImmediately);
    return response.data;
  },
);

export const resumeSubscription = createAsyncThunk(
  'clients/resumeSubscription',
  async (params: { clinicId: string }) => {
    const response = await ApiClient.resumeSubscription(params.clinicId);
    return response.data;
  },
);

export const reactivateAsTrial = createAsyncThunk(
  'clients/reactivateAsTrial',
  async (params: { clinicId: string; trialDays?: number }) => {
    const response = await ApiClient.reactivateAsTrial(params.clinicId, params.trialDays);
    return response.data;
  },
);
