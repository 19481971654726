import { Form, ModalProps, Radio, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import Button from 'src/components/button';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import { ConfirmModal } from 'src/components/modals';
import { BaseText } from 'src/components/text';
import { TextArea } from 'src/components/text-area';
import { STRING_NOT_ONLY_SPACE_REGEX } from 'src/variables/constants';
import { DashboardVideo, DashboardVideoType } from 'src/services/dashboard-videos-service';
import { ETrainingMaterialAction, ITrainingMaterialFormValues } from '../constants';

import './TrainingMaterialActionModal.scss';

interface ITrainingMaterialActionModalProps extends ModalProps {
  type: ETrainingMaterialAction;
  defaultMaterial?: DashboardVideo;
  onCreate: (values: ITrainingMaterialFormValues) => void;
  onUpdate: (values: ITrainingMaterialFormValues) => void;
  onClose: () => void;
  onDelete: () => void;
}

const TrainingMaterialActionModal = (props: ITrainingMaterialActionModalProps) => {
  const { type, defaultMaterial, onCreate, onUpdate, onClose, onDelete, ...modalProps } = props;
  const [form] = Form.useForm<ITrainingMaterialFormValues>();
  const [materialType] = useState<DashboardVideoType>(DashboardVideoType.VIDEO);

  useEffect(() => {
    if (defaultMaterial) {
      form.setFieldsValue({
        title: defaultMaterial.title,
        description: defaultMaterial.description,
        type: DashboardVideoType.VIDEO,
        duration: defaultMaterial.duration,
        thumbnail: defaultMaterial.thumbnail,
        url: defaultMaterial.url,
      });
    } else {
      form.setFieldsValue({
        type: DashboardVideoType.VIDEO
      });
    }
  }, [defaultMaterial, form]);

  const onCloseModal = () => {
    onClose();
    form.resetFields();
  };

  useEffect(() => {
    if (modalProps.open === false) {
      form.resetFields();
      form.setFieldsValue({
        type: DashboardVideoType.VIDEO
      });
    }
  }, [modalProps.open, form]);

  const modalHeader = useMemo(() => {
    switch (type) {
      case ETrainingMaterialAction.ADD_NEW:
        return 'Add New Training Video';
      case ETrainingMaterialAction.EDIT:
        return 'Edit Training Video';
      case ETrainingMaterialAction.DELETE:
        return 'Delete Training Video';
    }
  }, [type]);

  return (
    <ConfirmModal
      {...modalProps}
      onCancel={onCloseModal}
      modalHeader={modalHeader}
      className="TrainingMaterialActionModal"
      width={700}
    >
      <Form onFinish={type === ETrainingMaterialAction.ADD_NEW ? onCreate : onUpdate} form={form} layout="vertical">
        {type !== ETrainingMaterialAction.DELETE && (
          <div className="TrainingMaterialActionModal__input-fields">
            <FormItem name="type" style={{ display: 'none' }}>
              <Input type="hidden" />
            </FormItem>

            <div>
              <FormItem
                label={<BaseText className="hasRequireMark">YouTube URL</BaseText>}
                name="url"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the YouTube video URL',
                  },
                  {
                    type: 'url',
                    message: 'Please enter a valid URL',
                  },
                  {
                    validator: (_, value) => {
                      if (!value) return Promise.resolve();
                      
                      const isYoutubeUrl = value.includes('youtube.com') || value.includes('youtu.be');
                      return isYoutubeUrl 
                        ? Promise.resolve() 
                        : Promise.reject('Please enter a valid YouTube URL');
                    }
                  }
                ]}
                help="All metadata (title, thumbnail, duration) will be automatically extracted from YouTube"
              >
                <Input placeholder="https://www.youtube.com/watch?v=videoId or https://youtu.be/videoId" />
              </FormItem>
            </div>

            <div>
              <FormItem
                label={<BaseText>Title (Optional - Will use YouTube title if empty)</BaseText>}
                name="title"
                rules={[
                  { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter a valid title' },
                  {
                    max: 100,
                    message: `Maximum length for this field is 100 characters`,
                  },
                ]}
              >
                <Input />
              </FormItem>
            </div>

            <div>
              <FormItem
                label={<BaseText>Description (Optional)</BaseText>}
                name="description"
                rules={[
                  {
                    max: 500,
                    message: `Maximum length for this field is 500 characters`,
                  },
                ]}
              >
                <TextArea rows={4} />
              </FormItem>
            </div>
          </div>
        )}
        {type === ETrainingMaterialAction.DELETE && (
          <BaseText textAlign="center">Do you want to delete this training video?</BaseText>
        )}
        <div className={`TrainingMaterialActionModal__buttons ${type === ETrainingMaterialAction.DELETE && 'delete'}`}>
          <Button
            type="primary"
            className="TrainingMaterialActionModal__button"
            htmlType={type === ETrainingMaterialAction.DELETE ? 'button' : 'submit'}
            onClick={type === ETrainingMaterialAction.DELETE ? onDelete : undefined}
          >
            {type === ETrainingMaterialAction.DELETE ? 'Delete' : 'Save'}
          </Button>
          <Button className="TrainingMaterialActionModal__button" onClick={onCloseModal}>
            Cancel
          </Button>
        </div>
      </Form>
    </ConfirmModal>
  );
};

export default TrainingMaterialActionModal; 